import { ref, watch } from 'vue';
import { Close } from '@element-plus/icons-vue';
export default {
  props: {
    //弹窗标题
    title: {
      type: String,
      default: 'Title'
    },
    //弹窗宽度
    width: {
      type: String,
      default: '640px'
    },
    //弹窗高度
    top: {
      type: String,
      default: '25vh'
    },
    //打开关闭
    visible: {
      type: Boolean,
      default: true
    },
    //确认按钮文本
    confirm: {
      type: String,
      default: ''
    },
    //取消按钮文本
    cancel: {
      type: String,
      default: ''
    },
    //标题是否居中
    center: {
      type: Boolean,
      default: true
    },
    //自定义class
    customClass: {
      type: String,
      default: ''
    }
  },
  components: {
    Close
  },
  emits: ['confirm', 'cancel', 'visible-change', 'opened'],
  setup(props, {
    emit
  }) {
    //data
    const dialogVisible = ref(props.visible);

    //watch
    watch(() => props.visible, newVal => {
      dialogVisible.value = newVal;
    });
    watch(dialogVisible, newVal => {
      emit('visible-change', newVal);
    });

    //function
    //点击确认
    function handleClickConfirm() {
      emit('confirm');
    }

    //点击取消
    function handleClickCancel() {
      emit('cancel');
    }

    //打开完成
    function opened() {
      emit('opened');
    }
    return {
      //data
      dialogVisible,
      //function
      handleClickConfirm,
      handleClickCancel,
      opened
    };
  }
};